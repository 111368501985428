<template>
  <div class="policy_wrap">
    <div class="header">
      <div
        class="tab"
        :class="{ selected: this.$route.name == 'term' }"
        @click="this.$router.push('/policy/term')"
      >
        {{ this.$t("footer.agreement") }}
      </div>
      <div
        class="tab"
        :class="{ selected: this.$route.name == 'privacy' }"
        @click="this.$router.push('/policy/privacy')"
      >
        {{ this.$t("footer.privacy_policy") }}
      </div>
    </div>

    <div class="body">
      <router-view :key="$route.path"></router-view>
    </div>
    <div class="footer">
      <button class="button red sharp" @click="this.$router.push('/')">
        {{ this.$t("common.button_main") }}
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "Policy",
};
</script>

<style scoped>
.policy_wrap {
  width: 1200px;
  max-width: 100%;

  margin: 4rem auto 6rem auto;
  word-wrap: break-word;
  word-break: keep-all;
}

.policy_wrap > .header {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: stretch;
  /* box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24); */
  border: 1px solid #dedede;
}
.policy_wrap > .header > .tab {
  width: 50%;
  font-size: 1.6rem;
  font-weight: 700;
  color: #b8b8b8;
  padding: 1.2rem;
  text-align: center;
  cursor: pointer;
}

.policy_wrap > .header > .tab.selected {
  color: #333;
  border-bottom: 4px #333 inset;
}

.policy_wrap > .body {
  padding: 4rem;
  background: #fff;
  margin-top: 2rem;
  /* box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24); */
  border: 1px solid #dedede;

  column-count: 2;
  column-gap: 4rem;
  padding-bottom: 2.4rem;
  font-size: 1rem;
  font-weight: 300;
}

.policy_wrap > .body:deep(h2) {
  font-size: 1.2rem;
  margin: 0.875rem 0 0.7rem 0;
  padding: 0;
}
.policy_wrap > .body:deep(h3) {
  font-size: 1rem;
  font-weight: 400;
}
.policy_wrap > .body:deep(ol) {
  padding-left: 2.4rem;
}

.policy_wrap > .footer {
  text-align: center;
  margin: 2rem 0 0 0;
}
</style>
